export default {
  butt: {
    butt: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "GRINDING butts with a partner who has an STI when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GRINDING butts with a partner who has an STI when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "GRINDING butts with a partner who has an STI when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GRINDING butts with a partner who has an STI when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "GRINDING butts with a partner who has an STI puts you at LOW risk for STIs. There is some skin-to-skin contact, but the areas around the anus are not likley to touch. So, it is difficult for STIs to be transmitted."
          },
          uplus: {
            value: 0.25,
            explain:
              "GRINDING butts with a partner who has an STI puts you at LOW risk for STIs. There is some skin-to-skin contact, but the areas around the anus are not likley to touch. So, it is difficult for STIs to be transmitted. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "GRINDING butts with a partner who has an STI puts you at LOW risk for STIs. There is some skin-to-skin contact, but the areas around the anus are not likley to touch. So, it is difficult for STIs to be transmitted. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "GRINDING butts with a partner who has an STI puts you at LOW risk for STIs. There is some skin-to-skin contact, but the areas around the anus are not likley to touch. So, it is difficult for STIs to be transmitted. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    hand: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "Getting your butt FINGERED by a partner who has an STI when using a barrier (e.g., condom) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting your butt FINGERED by a partner who has an STI when using a barrier (e.g., condom) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "Getting your butt FINGERED by a partner who has an STI when using a barrier (e.g., condom) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting your butt FINGERED by a partner who has an STI when using a barrier (e.g., condom) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "Getting your butt FINGERED by a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting your butt FINGERED by a partner who has an STI puts you at LOW risk for STIs.Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "Getting your butt FINGERED by a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting your butt FINGERED by a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    mouth: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "Getting your butt RIMMED by a partner who has an STI when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting your butt RIMMED by a partner who has an STI when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "Getting your butt RIMMED by a partner who has an STI when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting your butt RIMMED by a partner who has an STI when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "Getting your butt RIMMED by a partner who has an STI without a barrier (e.g., dental dam) might result in getting STIs in your rectum or on your butt or anus. Use a barrier to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Getting your butt RIMMED by a partner who has an STI without a barrier (e.g., dental dam) might result in getting STIs in your rectum or on your butt or anus. Use a barrier to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "Getting your butt RIMMED by a partner who has an STI without a barrier (e.g., dental dam) might result in getting STIs in your rectum or on your butt or anus. Use a barrier to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Getting your butt RIMMED by a partner who has an STI without a barrier (e.g., dental dam) might result in getting STIs in your rectum or on your butt or anus. Use a barrier to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    penis: {
      condom: {
        null: {
          null: {
            value: 0.75,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.75,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 1.25,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 1.25,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You are the BOTTOM partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    vagina: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "GRINDING your butt with your partner's vagina when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GRINDING your butt with your partner's vagina when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "GRINDING your butt with your partner's vagina when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GRINDING your butt with your partner's vagina when using a barrier (e.g., dental dam, clothes) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "GRINDING your butt with your partner's vagina without a barrier (e.g., dental dam, clothes) might result in getting an STI on your butt. Use a barrier to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GRINDING your butt with your partner's vagina without a barrier (e.g., dental dam, clothes) might result in getting an STI on your butt. Use a barrier to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "GRINDING your butt with your partner's vagina without a barrier (e.g., dental dam, clothes) might result in getting an STI on your butt. Use a barrier to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GRINDING your butt with your partner's vagina without a barrier (e.g., dental dam, clothes) might result in getting an STI on your butt. Use a barrier to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    }
  },
  hand: {
    butt: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "FINGERING your partner's butt with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "FINGERING your partner's butt with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "FINGERING your partner's butt with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "FINGERING your partner's butt with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "FINGERING your partner's butt puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand."
          },
          uplus: {
            value: 0.25,
            explain:
              "FINGERING your partner's butt puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "FINGERING your partner's butt puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "FINGERING your partner's butt puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    hand: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Holding hands is NOT a risk for STIs."
          }
        }
      }
    },
    mouth: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Getting your fingers sucked is NOT a risk for STIs."
          }
        }
      }
    },
    penis: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "Giving a partner who has an STI a HANDJOB with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Giving a partner who has an STI a HANDJOB with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "Giving a partner who has an STI a HANDJOB with a condom puts you at LOW risk for STIs. If there is no genital skin-to-skin contact or oral sex with a partner who has an STI, it is difficult for STIs to be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Giving a partner who has an STI a HANDJOB with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "Giving a partner who has an STI a HANDJOB puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand."
          },
          uplus: {
            value: 0.25,
            explain:
              "Giving a partner who has an STI a HANDJOB puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "Giving a partner who has an STI a HANDJOB puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "Giving a partner who has an STI a HANDJOB puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    vagina: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "FINGERING your partner's vagina with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "FINGERING your partner's vagina with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "FINGERING your partner's vagina with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "FINGERING your partner's vagina with a condom puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "FINGERING your partner's vagina puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand."
          },
          uplus: {
            value: 0.25,
            explain:
              "FINGERING your partner's vagina puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "FINGERING your partner's vagina puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "FINGERING your partner's vagina puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if you touch your own genitals after touching theirs, but it is generally difficult to transmit most STIs by hand. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    }
  },
  mouth: {
    butt: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "RIMMING your partner's butt when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "RIMMING your partner's butt when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "RIMMING your partner's butt when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "RIMMING your partner's butt when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "RIMMING your partner's butt without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "RIMMING your partner's butt without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "RIMMING your partner's butt without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "RIMMING your partner's butt without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    hand: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          },
          uplus: {
            value: 0.01,
            explain: "Sucking on your partner's fingers is NOT a risk for STIs."
          }
        }
      }
    },
    mouth: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "KISSING a partner who has an oral STI when using a barrier (e.g., dental dam) puts you at NO risk for an STI. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "KISSING a partner who has an oral STI when using a barrier (e.g., dental dam) puts you at NO risk for an STI. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "KISSING a partner who has an oral STI when using a barrier (e.g., dental dam) puts you at NO risk for an STI. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "KISSING a partner who has an oral STI when using a barrier (e.g., dental dam) puts you at NO risk for an STI. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "KISSING a partner who has an STI puts you at LOW risk for an STI. Some STIs like HPV, syphilis, and gonorrhea may infect the lips, mouth, and throat. But, it is generally difficult to transmit STIs through kissing."
          },
          uplus: {
            value: 0.25,
            explain:
              "KISSING a partner who has an STI puts you at LOW risk for an STI. Some STIs like HPV, syphilis, and gonorrhea may infect the lips, mouth, and throat. But, it is generally difficult to transmit STIs through kissing. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "KISSING a partner who has an STI puts you at LOW risk for an STI. Some STIs like HPV, syphilis, and gonorrhea may infect the lips, mouth, and throat. But, it is generally difficult to transmit STIs through kissing. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "KISSING a partner who has an STI puts you at LOW risk for an STI. Some STIs like HPV, syphilis, and gonorrhea may infect the lips, mouth, and throat. But, it is generally difficult to transmit STIs through kissing. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    penis: {
      condom: {
        null: {
          null: {
            value: 0.25,
            explain:
              "Giving a BLOWJOB to a partner who has an STI but has a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.25,
            explain:
              "Giving a BLOWJOB to a partner who has an STI but has a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "Giving a BLOWJOB to a partner who has an STI but has a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "Giving a BLOWJOB to a partner who has an STI but has a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "Giving a BLOWJOB to a partner who has an STI without a condom might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Giving a BLOWJOB to a partner who has an STI without a condom might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "Giving a BLOWJOB to a partner who has an STI without a condom might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Giving a BLOWJOB to a partner who has an STI without a condom might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    vagina: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "GOING DOWN on your partner's vagina when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GOING DOWN on your partner's vagina when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "GOING DOWN on your partner's vagina when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "GOING DOWN on your partner's vagina when using a barrier (e.g., dental dam) puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "GOING DOWN on your partner's vagina without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GOING DOWN on your partner's vagina without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "GOING DOWN on your partner's vagina without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GOING DOWN on your partner's vagina without a barrier (e.g., dental dam) might result in getting STIs in the throat or on your lips and mouth. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    }
  },
  penis: {
    butt: {
      condom: {
        null: {
          null: {
            value: 0.75,
            explain:
              "You are the TOP partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.75,
            explain:
              "You are the TOP partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "You are the TOP partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "You are the TOP partner during ANAL sex, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and butt may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 1.25,
            explain:
              "You are the TOP partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You are the TOP partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 1.25,
            explain:
              "You are the TOP partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You are the TOP partner during ANAL sex, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    hand: {
      condom: {
        null: {
          null: {
            value: 0.01,
            explain:
              "Getting a HANDJOB from a partner who has an STI when you have a condom on puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting a HANDJOB from a partner who has an STI when you have a condom on puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        },
        prep: {
          null: {
            value: 0.01,
            explain:
              "Getting a HANDJOB from a partner who has an STI when you have a condom on puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          },
          uplus: {
            value: 0.01,
            explain:
              "Getting a HANDJOB from a partner who has an STI when you have a condom on puts you at NO risk for STIs. There is no genital skin-to-skin contact or fluid exchange, so STIs cannot be transmitted."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.25,
            explain:
              "Getting a HANDJOB from a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting a HANDJOB from a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "Getting a HANDJOB from a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting a HANDJOB from a partner who has an STI puts you at LOW risk for STIs. Some STIs like HPV and herpes may be transmitted if they touch their own genitals before touching you, but it is generally difficult to transmit most STIs by hand. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    mouth: {
      condom: {
        null: {
          null: {
            value: 0.25,
            explain:
              "Getting a BLOWJOB from a partner who has an STI when you have a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting a BLOWJOB from a partner who has an STI when you have a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "Getting a BLOWJOB from a partner who has an STI when you have a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "Getting a BLOWJOB from a partner who has an STI when you have a condom on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and mouth may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "Getting a BLOWJOB without a condom from a partner who has an STI might result in getting STIs on your penis or in your urinary tract. Use condoms to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Getting a BLOWJOB without a condom from a partner who has an STI might result in getting STIs on your penis or in your urinary tract. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "Getting a BLOWJOB without a condom from a partner who has an STI might result in getting STIs on your penis or in your urinary tract. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "Getting a BLOWJOB without a condom from a partner who has an STI might result in getting STIs on your penis or in your urinary tract. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    penis: {
      condom: {
        null: {
          null: {
            value: 0.25,
            explain:
              "GRINDING your penis and your partner's penis together with condoms on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, the areas around your penis may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.25,
            explain:
              "GRINDING your penis and your partner's penis together with condoms on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, the areas around your penis may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.25,
            explain:
              "GRINDING your penis and your partner's penis together with condoms on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, the areas around your penis may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.25,
            explain:
              "GRINDING your penis and your partner's penis together with condoms on puts you at LOW risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, the areas around your penis may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 0.75,
            explain:
              "GRINDING your penis and your partner's penis together without a condom might result in getting an STI on your penis. Use condoms to reduce your risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GRINDING your penis and your partner's penis together without a condom might result in getting an STI on your penis. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "GRINDING your penis and your partner's penis together without a condom might result in getting an STI on your penis. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "GRINDING your penis and your partner's penis together without a condom might result in getting an STI on your penis. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    },
    vagina: {
      condom: {
        null: {
          null: {
            value: 0.75,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and vagina may still touch, which could transmit some STIs like herpes and HPV."
          },
          uplus: {
            value: 0.75,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and vagina may still touch, which could transmit some STIs like herpes and HPV. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 0.75,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and vagina may still touch, which could transmit some STIs like herpes and HPV. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 0.75,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you use condoms. This behavior puts you at MEDIUM risk for STIs. condoms block most genital skin-to-skin contact and fluid exchange. But, some areas around the penis and vagina may still touch, which could transmit some STIs like herpes and HPV. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      },
      null: {
        null: {
          null: {
            value: 1.25,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Having an HIV-undetectable partner does not change your STI risk."
          }
        },
        prep: {
          null: {
            value: 1.25,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. Being on PrEP does not change your STI risk."
          },
          uplus: {
            value: 1.25,
            explain:
              "You have VAGINAL sex with a partner who has an STI, and you do not use condoms. This behavior puts you at HIGH risk for STIs. Genital skin-to-skin contact and potential fluid exchange make it easier for STIs to be transmitted. Use condoms to reduce your risk. PrEP and having an HIV-undetectable partner do not change your STI risk."
          }
        }
      }
    }
  }
};
